import axios from "axios";
import Swal from "sweetalert2";
import colors from "./Colors.config";
import Request from "../interfaces/Request.interface"
import Response from "../interfaces/Response.interface";

const estaEnProduccion = true;

// const URLBaseLocal = "http://127.0.0.1:10000/api/solicitud-credito";
const URLBaseLocal = "http://127.0.0.1:10000/api/solicitud-credito";
const URLBaseProduccion = "https://tzedaka.com.mx/api/solicitud-credito";


const executeRequest = async (request: Request): Promise<Response> => {
    let response: any;
    let data: Response;

    try {
        data = await axios({
            url: request.url,  
            method: request.metodo,
            headers: request.headers,
            data: request.data
        });
        if (data.data.error === true) messageError(data.data.message)
        response = {
            error: data.data.error,
            message: data.data.message,
            data: data.data
        };
    } catch (error) {
        messageError(error);
        response = {
            error: true,
            data: []
        };
    }
    return response;

}

const messageError = (error: any): void => {
    Swal.fire({
        icon: "error",
        title: "Error en la petición",
        text: error.message,
        showConfirmButton: true,
        confirmButtonColor: colors.blue,
        timerProgressBar: true,
        footer: JSON.stringify(error)
    });
}

const ApiConfig = {
    URLBase: estaEnProduccion ? URLBaseProduccion : URLBaseLocal,
    executeRequest
}

export default ApiConfig;