import { FC, useEffect, useState, ChangeEvent, FocusEvent, SyntheticEvent, KeyboardEvent } from "react";
import Device from "../../../interfaces/Device.interface";
import DeviceConfig from "../../../config/Device.config";
import colors from "../../../config/Colors.config";
import Block from "../../../components/Block.component";
import { Button, /*Card,*/ Col, DropdownItem, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Tabs } from "@mui/material";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ClienteModel from "../../../models/Cliente.model";
import Styles from "../../../assets/css/Styles.css";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/system';
import { Cached, Mail, Phone, Search, Close, PostAdd, Check } from "@material-ui/icons";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Estado from "../../../interfaces/Estado.interface";
import Response from "../../../interfaces/Response.interface";
import EstadoService from "../../../services/Estado.service";
import ClienteService from "../../../services/Cliente.service";
import Cliente from "../../../interfaces/Cliente.interface";
// import Cliente as Aval from "../../../interfaces/Cliente.interface";
import Pais from "../../../interfaces/Pais.interface";
import Number from "../../../tools/Number.tool";
import { IconButton } from "@mui/material";
import CiudadService from "../../../services/Ciudad.service";
import Ciudad from "../../../interfaces/Ciudad.interface";
import NivelEstudio from "../../../interfaces/NivelEstudio.interface";
import NivelEstudioService from "../../../services/NivelEstudio.service";
import EstadoCivil from "../../../interfaces/EstadoCivil.interface";
import EstadoCivilService from "../../../services/EstadoCivil.service";
import Components from "../../../tools/Components.tool";
import LocalidadService from "../../../services/Localidad.service";
import Localidad from "../../../interfaces/Localidad.interface";
import TipoVivienda from "../../../interfaces/TipoVivienda.interface";
import TiposViviendaService from "../../../services/TipoVivienda.service";
import imgTzedakaLogo from "../../../assets/img/logoTzedaka.png";
import { ToastContainer, toast } from "react-toastify";
// import imgProfile from "../../../assets/img/profile.png";
import Religion from "../../../interfaces/Religion.interface";
import imgBuscarCliente from "../../../assets/img/imgBuscarCliente.svg";
import ReligionService from "../../../services/Religion.service";
import TipoLocal from "../../../interfaces/TipoLocal.interface";
import Tiempo from "../../../tools/Tiempo.tool";
import Moneda from "../../../tools/Moneda.tool";
// import ArchivoTool from "../../../tools/Archivo.tool";
// import FileBase64 from "../../../interfaces/FileBase64.interface";
// import Image from "../../../tools/Imagen.tool";

import imgAgregar from "../../../assets/icon/agregar.png";
import Image from "../../../tools/Imagen.tool";
import ClienteCorto from "../../../interfaces/ClienteCorto.interface";
import Table, { TableColumn } from "react-data-table-component";

const InfoCliente: FC<{ changeIndexPage: any }> = ({ changeIndexPage }) => {

    const [cliente, setCliente] = useState<Cliente>(ClienteModel.getEstructura());
    const [clienteLista, setClienteLista] = useState<ClienteCorto[]>([]);
    const [aval, setAval] = useState<Cliente>(ClienteModel.getEstructura());
    const [textBuscar, setTextBuscar] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());
    const [value, setValue] = useState<string>('1');

    const [estadosNacimiento, setEstadosNacimiento] = useState<Estado[]>([]);
    const [estadoNacimiento, setEstadoNacimiento] = useState<Estado>({ IdEstado: 0, estado: "Seleccione un estado" });

    const [estadosVivienda, setEstadosVivienda] = useState<Estado[]>([]);
    const [estadoVivienda, setEstadoVivienda] = useState<Estado>({ IdEstado: 0, estado: "Seleccione un estado" });

    const [ciudadesNacimiento, setCiudadesNacimiento] = useState<Ciudad[]>([]);
    const [ciudadNacimiento, setCiudadNacimiento] = useState<Ciudad>({ IdCiudad: 0, ciudad: "Seleccione una ciudad" });

    const [ciudadesVivienda, setCiudadesVivienda] = useState<Ciudad[]>([]);
    const [ciudadVivienda, setCiudadVivienda] = useState<Ciudad>({ IdCiudad: 0, ciudad: "Seleccione una ciudad" });

    const paisesLista: Pais[] = [{ IdPais: 1, pais: "México" }];
    const [paisNacimiento, setPaisNacimiento] = useState<Pais>({ IdPais: 0, pais: "Seleccione un país" });
    const [paisVivienda, setPaisVivienda] = useState<Pais>({ IdPais: 0, pais: "Seleccione un país" });


    const [nivelesEstudio, setNivelesEstudio] = useState<NivelEstudio[]>([])
    const [nivelEstudio, setNivelEstudio] = useState<NivelEstudio>({ IdNivelEstudio: 0, nivelEstudio: "Seleccione un nivel de estudio" });

    const [estadosCiviles, setEstadosCiviles] = useState<EstadoCivil[]>([]);
    const [estadoCivil, setEstadoCivil] = useState<EstadoCivil>({ IdEstadoCivil: 0, estadoCivil: "Seleccione un nivel de estudio" });

    const [localidadesVivienda, setLocalidadesVivienda] = useState<Localidad[]>([]);
    const [localidadVivienda, setLocalidadVivienda] = useState<Localidad>({ IdLocalidad: 0, localidad: "Seleccione una localidad" });

    const [tiposVivienda, setTiposVivienda] = useState<TipoVivienda[]>([]);
    const [tipoVivienda, setTipoVivienda] = useState<TipoVivienda>({ IdTipoVivienda: 0, tipoVivienda: "Seleccione un tipo de vivienda" });

    const [religiones, setReligiones] = useState<Religion[]>([]);
    const [religion, setReligion] = useState<Religion>({ IdReligion: 0, religion: "Seleccione una religion" });

    const [tiposLocales, setTiposLocales] = useState<TipoLocal[]>([]);
    const [tipoLocal, setTipoLocal] = useState<TipoLocal>({ IdTipoLocal: 0, tipoLocal: "Seleccione un tipo de local" });

    const [erroresMensaje, setErroresMensaje] = useState<string[]>([]);

    const [isOpenModalErrores, setIsOpenModalErrores] = useState<boolean>(false);
    const [isOpenModalListaAval, setIsOpenModalListaAval] = useState<boolean>(false);

    const location = useLocation();
    const navigate = useNavigate();


    const listarEstados = async (): Promise<void> => {
        const response: Response = await EstadoService.listar();
        if (response.error === false) {
            const estadosListar: Estado[] = response.data.value;
            setEstadosNacimiento(estadosListar);
            setEstadosVivienda(estadosListar);
        }
    }

    const listarCiudadesNacimiento = async (IdEstado: number): Promise<void> => {
        const response: Response = await CiudadService.listar(IdEstado);
        if (response.data.error === false) {
            const ciudadesNacimiento: Ciudad[] = response.data.value;
            setCiudadesNacimiento(ciudadesNacimiento);
        }
    }

    const listarCiudadesVivienda = async (IdEstado: number): Promise<void> => {
        const response: Response = await CiudadService.listar(IdEstado);
        if (response.data.error === false) {
            const ciudadesVivienda: Ciudad[] = response.data.value;
            setCiudadesVivienda(ciudadesVivienda);
        }
    }

    const listarNivelesEstudio = async (): Promise<void> => {
        const response: Response = await NivelEstudioService.listar();
        if (response.data.error === false) {
            const nivelesEstudio: NivelEstudio[] = response.data.value;
            setNivelesEstudio(nivelesEstudio);
        }
    }

    const listarEstadoCiviles = async (): Promise<void> => {
        const response: Response = await EstadoCivilService.listar();
        if (response.data.error === false) {
            const estadosCiviles: EstadoCivil[] = response.data.value;
            setEstadosCiviles(estadosCiviles);
        }
    }

    const listarLocalidades = async (IdCiudad: number): Promise<void> => {
        const response: Response = await LocalidadService.listar(IdCiudad);
        if (response.data.error === false) {
            const localidadesVivienda: Localidad[] = response.data.value;
            setLocalidadesVivienda(localidadesVivienda);
        }

    }

    const listarTiposVivienda = async (): Promise<void> => {
        const response: Response = await TiposViviendaService.listar();
        if (response.data.error === false) {
            const tiposVivienda: TipoVivienda[] = response.data.value;
            setTiposVivienda(tiposVivienda);
        }
    }

    const listarReligiones = async (): Promise<void> => {
        const response: Response = await ReligionService.listar();
        if (response.data.error === false) {
            const religiones: Religion[] = response.data.value;
            setReligiones(religiones);
        }
    }

    const cargarCliente = async (IdCliente: string): Promise<void> => {
        let response: Response = await ClienteService.obtener(IdCliente);
        if (response.error === false) {
            let cliente: Cliente = response.data.value;
            //console.clear()
            cliente = {
                ...cliente,
                gastosPropios: {
                    ...cliente.gastosPropios,
                    renta: Moneda.castNumeroConDecimales(cliente.gastosPropios.renta),
                    agua: Moneda.castNumeroConDecimales(cliente.gastosPropios.agua),
                    educacion: Moneda.castNumeroConDecimales(cliente.gastosPropios.educacion),
                    internet: Moneda.castNumeroConDecimales(cliente.gastosPropios.internet),
                    luz: Moneda.castNumeroConDecimales(cliente.gastosPropios.luz),
                    otros: Moneda.castNumeroConDecimales(cliente.gastosPropios.otros),
                    salud: Moneda.castNumeroConDecimales(cliente.gastosPropios.salud),
                },
                trabajoNegocio: {
                    ...cliente.trabajoNegocio,
                    gastos: {
                        ...cliente.trabajoNegocio.gastos,
                        agua: Moneda.castNumeroConDecimales(cliente.gastosPropios.agua),
                        internet: Moneda.castNumeroConDecimales(cliente.gastosPropios.internet),
                        educacion: Moneda.castNumeroConDecimales(cliente.gastosPropios.educacion),
                        luz: Moneda.castNumeroConDecimales(cliente.gastosPropios.luz),
                        otros: Moneda.castNumeroConDecimales(cliente.gastosPropios.otros),
                        renta: Moneda.castNumeroConDecimales(cliente.gastosPropios.renta),
                        salud: Moneda.castNumeroConDecimales(cliente.gastosPropios.salud),
                    },
                    ingresoMensual: Moneda.castNumeroConDecimales(cliente.trabajoNegocio.ingresoMensual),
                    declaracionPatrimonial: {
                        ...cliente.trabajoNegocio.declaracionPatrimonial,
                        equipo: Moneda.castNumeroConDecimales(cliente.trabajoNegocio.declaracionPatrimonial.equipo),
                        otros: Moneda.castNumeroConDecimales(cliente.trabajoNegocio.declaracionPatrimonial.otros),
                        vehiculos: Moneda.castNumeroConDecimales(cliente.trabajoNegocio.declaracionPatrimonial.vehiculos),
                        vivienda: Moneda.castNumeroConDecimales(cliente.trabajoNegocio.declaracionPatrimonial.vivienda),
                    }
                },

            }
            setCliente(cliente);
            setPaisNacimiento(cliente.lugarNacimiento.pais);
            setPaisVivienda(cliente.lugarVivienda.pais);

            setEstadoNacimiento(cliente.lugarNacimiento.estado);
            setEstadoVivienda(cliente.lugarVivienda.estado);

            listarCiudadesNacimiento(cliente.lugarNacimiento.estado.IdEstado);
            listarCiudadesVivienda(cliente.lugarVivienda.estado.IdEstado);
            setCiudadNacimiento(cliente.lugarNacimiento.ciudad);
            setCiudadVivienda(cliente.lugarVivienda.ciudad);

            setNivelEstudio(cliente.nivelEstudio);
            setEstadoCivil(cliente.estadoCivil);

            listarLocalidades(cliente.lugarVivienda.ciudad.IdCiudad);
            setLocalidadVivienda(cliente.lugarVivienda.localidad);

            setTipoVivienda(cliente.lugarVivienda.tipoVivienda);

            setReligion(cliente.religion);

            response = await ClienteService.obtener(cliente.IdAval);
            if (response.error === false) {
                const aval: Cliente = response.data.value;
                setAval(aval);
            }

        }
    }

    const guardarCliente = async (): Promise<void> => {

        const value: { esValido: boolean, erroresMensaje: string[] } = ClienteModel.validarDatosCliente(cliente);

        if (value.esValido) {
            const response: Response = await ClienteService.guardar(cliente);
            const IdCliente: string = response.data.value;
            setCliente((prev: Cliente) => ({ ...prev, IdCliente }));
            toast.success("Datos guardados");
        } else {
            setErroresMensaje(value.erroresMensaje);
            setIsOpenModalErrores(true);
        }
    }

    const cargarURL = () => {
        console.clear();
        const parsedUrl = queryString.parseUrl(location.search);
        const { uuid } = parsedUrl.query;
        if (uuid !== "" && uuid !== "undefined" && uuid !== undefined) {
            navigate(`/dashboard/?page=2&uuid=${uuid}`);
            cargarCliente(`${uuid}`);
        } else {
            setCliente(ClienteModel.getEstructura());
        }
    }

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const MyTabs = styled(Tabs)(() => ({ '.MuiTabs-indicator': { backgroundColor: colors.blue, color: colors.blue }, }));
    const styleRow = { marginRight: 0, marginLeft: 0, marginTop: 10 };
    const styleCol = data.isDesktop ? { minWidth: "20%", width: "20%", maxWidth: "20%" } : { minWidth: "100%", width: "100%", maxWidth: "100%" };

    useEffect(() => {
        cargarURL();
        listarEstados();
        listarNivelesEstudio();
        listarEstadoCiviles();
        listarTiposVivienda();
        listarReligiones();
        const tiposLocales: TipoLocal[] = [
            { IdTipoLocal: 1, tipoLocal: "Rentado" },
            { IdTipoLocal: 2, tipoLocal: "Propio" }
        ];
        setTiposLocales(tiposLocales);
        Components.focus("educacion");
        Components.focus("nombres");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columnsClientes: TableColumn<ClienteCorto>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Num. </Label>,
            cell: (clienteCorto: ClienteCorto, index: number) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{index + 1}</Label>,
            width: "100px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > CLIENTE </Label>,
            cell: (clienteCorto: ClienteCorto) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{clienteCorto.nombres}</Label>,
            width: "400px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > CORREO </Label>,
            cell: (clienteCorto: ClienteCorto) => <Label style={{ color: colors.link, fontSize: 16, whiteSpace: 'normal', cursor: "pointer" }} onClick={() => { window.location.href = `mailto:${clienteCorto.correo.toLowerCase()}` }}>{clienteCorto.correo.toLowerCase()}</Label>,
            width: "300px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > TELÉFONO </Label>,
            cell: (clienteCorto: ClienteCorto) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal', textDecorationLine: "underline" }} onClick={() => { window.location.href = `tel:${clienteCorto.telefono}` }} >{Number.formatPhoneNumber(clienteCorto.telefono)}</Label>,
            width: "150px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > OCUPACIÓN </Label>,
            cell: (clienteCorto: ClienteCorto) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{clienteCorto.esTrabajador === 1 ? "ES TRABAJADOR" : "TIENE NEGOCIO"}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18, whiteSpace: 'normal' }} >  </Label>,
            cell: (clienteCorto: ClienteCorto) =>
                <>
                    <IconButton
                        style={{ backgroundColor: colors.white01, color: cliente.IdCliente === clienteCorto.IdCliente ? colors.grey : colors.blue }}
                        onClick={async () => {
                            if (cliente.IdCliente !== clienteCorto.IdCliente) {
                                const response: Response = await ClienteService.obtener(clienteCorto.IdCliente);
                                if (response.error === false) {
                                    const aval: Cliente = response.data.value ?? ClienteModel.getEstructura();
                                    setAval(aval);
                                    setCliente((prev: Cliente) => ({ ...prev, IdAval: aval.IdCliente }));
                                    setIsOpenModalListaAval(false);
                                }
                            }
                        }}
                        title={cliente.IdCliente === clienteCorto.IdCliente ? "No se puede seleccionar" : "Seleccionar aval"}
                    >
                        <Check />
                    </IconButton>
                </>
            ,
        },
    ]


    const listarClientes = async (text: string): Promise<void> => {
        const response: Response = await ClienteService.listar(text);
        const clienteLista: ClienteCorto[] = response.data.value;
        setClienteLista(clienteLista);

    };

    return (
        <>
            <ToastContainer limit={3} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white01 }} >
                <section style={{ marginLeft: data.isDesktop ? 80 : 0 }} >
                    <Block height={"2vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <Label style={{ fontSize: 30, }} >
                                INFORMACIÓN DEL CLIENTE
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <img src={imgTzedakaLogo} alt="/*-" style={{ height: 50, marginRight: 15 }} />
                        </Col>
                    </Row>

                    <Box sx={{ typography: 'body1', marginRight: 2, marginLeft: 2, }}>

                        <TabContext value={value}>

                            <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                <MyTabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="lab API tabs example"
                                    variant="scrollable" // Permite scroll automático en pantallas pequeñas
                                    scrollButtons="auto" // Muestra botones de desplazamiento cuando es necesario
                                >
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="Datos Generales" value="1" />
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="Empleo / Negocio" value="2" />
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="Referencias" value="3" />
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="Aval" value="4" />
                                    {/* <Tab style={{ color: colors.blue, fontSize: 16 }} label="Imágenes" value="4" /> */}
                                </MyTabs>
                            </Box>

                            {/* Datos generales del clietne */}
                            <TabPanel value="1" style={{ padding: 0 }} >
                                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                                    <Col>
                                        <Label style={{ fontSize: 24, }} >
                                            DATOS GENERALES
                                        </Label>
                                    </Col>
                                    <Col style={{ textAlign: "right" }} >
                                        <IconButton
                                            onClick={async () => {
                                                changeIndexPage(1);
                                            }}
                                            style={{ color: colors.blue, marginRight: 15 }}
                                            title={"Recargar datos del cliente"}
                                        >
                                            <Search />
                                        </IconButton>
                                        {
                                            cliente.IdCliente.length > 0 &&
                                            <IconButton
                                                onClick={async () => {
                                                    await cargarCliente(cliente.IdCliente);
                                                    toast.info("Datos cargados");
                                                }}
                                                style={{ color: colors.blue, marginRight: 15 }}
                                                title={"Recargar datos del cliente"}
                                            >
                                                <Cached />
                                            </IconButton>
                                        }


                                    </Col>
                                </Row>
                                <DropdownItem divider />
                                <Row style={styleRow} >
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Nombre del Cliente
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.nombres}
                                                    id="nombres"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, nombres: value }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Apellido Paterno
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.paterno}
                                                    id="paterno"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, paterno: value }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Apellido Materno
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.materno}
                                                    id="materno"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, materno: value }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Numero Teléfono
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.blue, cursor: "pointer" }}
                                                    title={`Marcar a ${Number.formatPhoneNumber(cliente.telefono)}`}
                                                >
                                                    <Phone />
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={Number.formatPhoneNumber(cliente.telefono)}
                                                    id="telefono"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, telefono: value }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Correo Electrónico
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.blue, cursor: "pointer" }}
                                                    title={`Mandar mensaje a: a@a.com`}
                                                >
                                                    <Mail />
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.correo}
                                                    id="correo"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, correo: value }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={styleRow} >
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                País de Nacimiento
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={paisesLista}
                                                    sx={Styles.select}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: Pais) => option.pais}
                                                    onChange={(event: ChangeEvent<{}>, value: any) => {
                                                        if (value) {
                                                            setPaisNacimiento(value);
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarNacimiento: { ...prev.lugarNacimiento, pais: value } }));
                                                        } else {
                                                            setPaisNacimiento({ IdPais: 1, pais: "México" });
                                                        }
                                                    }}
                                                    defaultValue={{ IdPais: 0, pais: "México" }}
                                                    value={paisNacimiento}
                                                //disabled
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Estado Nacimiento
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={estadosNacimiento}
                                                    sx={Styles.select}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: Estado) => option.estado}
                                                    onChange={(event: ChangeEvent<{}>, value: Estado | null) => {
                                                        if (value !== null) {
                                                            setEstadoNacimiento(value);
                                                            listarCiudadesNacimiento(value.IdEstado);
                                                            setCiudadNacimiento({ IdCiudad: 0, ciudad: "Seleccione una ciudad" });
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarNacimiento: { ...prev.lugarNacimiento, estado: { ...prev.lugarNacimiento.estado, IdEstado: value.IdEstado, } } }));
                                                        } else {
                                                            setEstadoNacimiento({ IdEstado: 0, estado: "Seleccione un estado" });
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarNacimiento: { ...prev.lugarNacimiento, estado: { ...prev.lugarNacimiento.estado, IdEstado: 0, } } }));
                                                        }
                                                    }}
                                                    defaultValue={{ IdEstado: 0, estado: "Seleccione un estado" }}
                                                    value={estadoNacimiento}
                                                // clearIcon={<Clear />} // Ocultar el botón de limpiar                                                    
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Ciudad de Nacimiento
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={ciudadesNacimiento}
                                                    sx={Styles.select}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: Ciudad) => option.ciudad}
                                                    onChange={(event: ChangeEvent<{}>, value: Ciudad | null) => {
                                                        if (value !== null) {
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarNacimiento: { ...prev.lugarNacimiento, ciudad: { ...prev.lugarNacimiento.ciudad, IdCiudad: value.IdCiudad } } }));
                                                            setCiudadNacimiento(value);
                                                        } else {
                                                            setCiudadNacimiento({ IdCiudad: 0, ciudad: "Seleccione una ciudad" });
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarNacimiento: { ...prev.lugarNacimiento, ciudad: { ...prev.lugarNacimiento.ciudad, IdCiudad: 0 } } }));
                                                        }
                                                    }}
                                                    defaultValue={{ IdCiudad: 0, ciudad: "Seleccione una ciudad" }}
                                                    value={ciudadNacimiento}
                                                // clearIcon={<Clear />} // Ocultar el botón de limpiar                                                    
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Nivel de Estudio
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={nivelesEstudio}
                                                    sx={Styles.select}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: NivelEstudio) => option.nivelEstudio}
                                                    onChange={(event: ChangeEvent<{}>, value: NivelEstudio | null) => {
                                                        if (value !== null) {
                                                            setNivelEstudio(value);
                                                            setCliente((prev: Cliente) => ({ ...prev, nivelEstudio: { ...prev.nivelEstudio, IdNivelEstudio: value.IdNivelEstudio } }));
                                                        } else {
                                                            setNivelEstudio({ IdNivelEstudio: 0, nivelEstudio: "Seleccione un nivel de estudio" });
                                                            setCliente((prev: Cliente) => ({ ...prev, nivelEstudio: { ...prev.nivelEstudio, IdNivelEstudio: 0 } }));
                                                        }
                                                    }}
                                                    defaultValue={{ IdNivelEstudio: 0, nivelEstudio: "Seleccione un nivel de estudio" }}
                                                    value={nivelEstudio}
                                                // clearIcon={<Clear />} // Ocultar el botón de limpiar                                                    
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Estado Civil
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={estadosCiviles}
                                                    sx={Styles.select}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: EstadoCivil) => option.estadoCivil}
                                                    onChange={(event: ChangeEvent<{}>, value: EstadoCivil | null) => {
                                                        if (value !== null) {
                                                            setEstadoCivil(value);
                                                            setCliente((prev: Cliente) => ({ ...prev, estadoCivil: { ...prev.estadoCivil, IdEstadoCivil: value.IdEstadoCivil } }));
                                                        } else {
                                                            setEstadoCivil({ IdEstadoCivil: 0, estadoCivil: "Seleccione un estado civil" });
                                                            setCliente((prev: Cliente) => ({ ...prev, estadoCivil: { ...prev.estadoCivil, IdEstadoCivil: 0 } }));
                                                        }
                                                    }}
                                                    defaultValue={{ IdEstadoCivil: 0, estadoCivil: "Seleccione un estado civil" }}
                                                    value={estadoCivil}
                                                // clearIcon={<Clear />} // Ocultar el botón de limpiar                                                    
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={styleRow} >
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Clave de Elector
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.ine.claveElector}
                                                    id="claveElector"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, ine: { ...prev.ine, claveElector: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                INE Folio
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.ine.ineFolio}
                                                    id="ineFolio"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, ine: { ...prev.ine, ineFolio: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                INE Numero
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.ine.ineNumero}
                                                    id="ineNumero"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, ine: { ...prev.ine, ineNumero: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                CURP
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.ine.curp}
                                                    id="curp"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, ine: { ...prev.ine, curp: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                RFC
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.ine.rfc}
                                                    id="rfc"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, ine: { ...prev.ine, rfc: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={styleRow} >
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                País de Vivienda
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={paisesLista}
                                                    sx={Styles.select}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: Pais) => option.pais}
                                                    onChange={(event: ChangeEvent<{}>, value: any) => {
                                                        if (value) {
                                                            console.log(value)
                                                            setPaisVivienda(value);
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarVivienda: { ...prev.lugarNacimiento, pais: value } }));
                                                        } else {
                                                            setPaisVivienda({ IdPais: 1, pais: "México" })
                                                        }
                                                    }}
                                                    defaultValue={{ IdPais: 0, pais: "México" }}
                                                    value={paisVivienda}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Estado de Vivienda
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={estadosVivienda}
                                                    sx={Styles.select}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: Estado) => option.estado}
                                                    onChange={(event: ChangeEvent<{}>, value: Estado | null) => {
                                                        if (value !== null) {
                                                            setEstadoVivienda(value);
                                                            listarCiudadesVivienda(value.IdEstado);
                                                            setCiudadVivienda({ IdCiudad: 0, ciudad: "Seleccione una ciudad" });
                                                            setLocalidadVivienda({ IdLocalidad: 0, localidad: "Seleccione una localidad de vivienda" });
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarVivienda: { ...prev.lugarVivienda, estado: { ...prev.lugarVivienda.estado, IdEstado: value.IdEstado } } }));
                                                        } else {
                                                            setEstadoVivienda({ IdEstado: 0, estado: "Seleccione un estado" });
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarVivienda: { ...prev.lugarVivienda, estado: { ...prev.lugarVivienda.estado, IdEstado: 0 } } }));
                                                        }
                                                    }}
                                                    defaultValue={{ IdEstado: 0, estado: "Seleccione un estado" }}
                                                    value={estadoVivienda}
                                                // clearIcon={<Clear />} // Ocultar el botón de limpiar                                                    
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Ciudad de Vivienda
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={ciudadesVivienda}
                                                    sx={Styles.select}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: Ciudad) => option.ciudad}
                                                    onChange={(event: ChangeEvent<{}>, value: Ciudad | null) => {
                                                        if (value !== null) {
                                                            setCiudadVivienda(value);
                                                            listarLocalidades(value.IdCiudad);
                                                            setLocalidadVivienda({ IdLocalidad: 0, localidad: "Seleccione una localidad de vivienda" });
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarVivienda: { ...prev.lugarVivienda, ciudad: { ...prev.lugarVivienda.ciudad, IdCiudad: value.IdCiudad } } }));
                                                        } else {
                                                            setCiudadVivienda({ IdCiudad: 0, ciudad: "Seleccione una ciudad" });
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarVivienda: { ...prev.lugarVivienda, ciudad: { ...prev.lugarVivienda.ciudad, IdCiudad: 0 } } }));
                                                        }
                                                    }}
                                                    defaultValue={{ IdCiudad: 0, ciudad: "Seleccione una ciudad" }}
                                                    value={ciudadVivienda}
                                                // clearIcon={<Clear />} // Ocultar el botón de limpiar                                                    
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Localidad de Vivienda
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={localidadesVivienda}
                                                    sx={Styles.select}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: Localidad) => option.localidad}
                                                    onChange={(event: ChangeEvent<{}>, value: Localidad | null) => {
                                                        if (value !== null) {
                                                            setLocalidadVivienda(value);
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarVivienda: { ...prev.lugarVivienda, localidad: { ...prev.lugarVivienda.localidad, IdLocalidad: value.IdLocalidad } } }));
                                                        } else {
                                                            setLocalidadVivienda({ IdLocalidad: 0, localidad: "Seleccione una localidad de vivienda" });
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarVivienda: { ...prev.lugarVivienda, localidad: { ...prev.lugarVivienda.localidad, IdLocalidad: 0 } } }));
                                                        }
                                                    }}
                                                    defaultValue={{ IdLocalidad: 0, localidad: "Seleccione una localidad de vivienda" }}
                                                    value={localidadVivienda}
                                                // clearIcon={<Clear />} // Ocultar el botón de limpiar                                                    
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Calle y Numero de Casa
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 18 }}
                                                    value={cliente.lugarVivienda.calleNumeroCasa}
                                                    id="calleNumeroCasa"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, lugarVivienda: { ...prev.lugarVivienda, calleNumeroCasa: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Tipo de Vivienda
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={tiposVivienda}
                                                    sx={Styles.select}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: TipoVivienda) => option.tipoVivienda}
                                                    onChange={(event: ChangeEvent<{}>, value: TipoVivienda | null) => {
                                                        if (value) {
                                                            setTipoVivienda(value);
                                                            setCliente((prev: Cliente) => ({ ...prev, lugarVivienda: { ...prev.lugarVivienda, tipoVivienda: value } }));
                                                        } else {
                                                            setTipoVivienda({ IdTipoVivienda: 0, tipoVivienda: "Seleccione una tipo de vivienda" })
                                                        }
                                                    }}
                                                    defaultValue={{ IdTipoVivienda: 0, tipoVivienda: "Seleccione una localidad de vivienda" }}
                                                    value={tipoVivienda}
                                                // clearIcon={<Clear />} // Ocultar el botón de limpiar                                                    
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Nombre del Cónyuge
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 18 }}
                                                    value={cliente.nombreConyuge}
                                                    id="nombreConyuge"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, nombreConyuge: value }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={data.isDesktop ? { minWidth: "60%", width: "60%", maxWidth: "60%" } : { minWidth: "100%", width: "100%", maxWidth: "100%" }} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Dependientes Económicos                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 18 }}
                                                    value={cliente.dependientesEconomicos}
                                                    id="dependientesEconomicos"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, dependientesEconomicos: value }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Gastos de Renta
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                >
                                                    $
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                    value={`${cliente.gastosPropios.renta}`}
                                                    id="renta"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        value = Number.singleNumbers(value);
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, renta: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, renta: Moneda.castNumeroConDecimales(value.replace(",", "")) } }));
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Gastos de Educación
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                >
                                                    $
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                    value={`${cliente.gastosPropios.educacion}`}
                                                    id="educacion"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        value = Number.singleNumbers(value)
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, educacion: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, educacion: Moneda.castNumeroConDecimales(value.replace(",", "")) } }));
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Gastos de Salud
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                >
                                                    $
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                    value={`${cliente.gastosPropios.salud}`}
                                                    id="salud"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        value = Number.singleNumbers(value)
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, salud: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, salud: Moneda.castNumeroConDecimales(value.replace(",", "")) } }));
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Gastos de Agua
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                >
                                                    $
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                    value={`${cliente.gastosPropios.agua}`}
                                                    id="agua"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        value = Number.singleNumbers(value)
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, agua: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, agua: Moneda.castNumeroConDecimales(value.replace(",", "")) } }));
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Gastos de Luz
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                >
                                                    $
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                    value={`${cliente.gastosPropios.luz}`}
                                                    id="luz"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        value = Number.singleNumbers(value)
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, luz: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, luz: Moneda.castNumeroConDecimales(value.replace(",", "")) } }));
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Gastos de Internet
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                >
                                                    $
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                    value={`${cliente.gastosPropios.internet}`}
                                                    id="internet"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        value = Number.singleNumbers(value)
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, internet: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, internet: Moneda.castNumeroConDecimales(value.replace(",", "")) } }));
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Otros Gastos
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                >
                                                    $
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                    value={`${cliente.gastosPropios.otros}`}
                                                    id="otros"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        value = Number.singleNumbers(value);
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, otros: value } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, otros: Moneda.castNumeroConDecimales(value.replace(",", "")) } }));
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Fecha de Nacimiento
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0, }}
                                                    value={cliente.fechaNacimiento.substring(0, 10)}
                                                    id="fechaNacimiento"
                                                    type="date"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, fechaNacimiento: value }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Religion que Profesa
                                            </Label>
                                            <InputGroup>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={religiones}
                                                    sx={{ ...Styles.select, marginTop: -2 }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    getOptionLabel={(option: Religion) => option.religion}
                                                    onChange={(event: ChangeEvent<{}>, value: Religion | null) => {
                                                        if (value !== null) {
                                                            setReligion(value);
                                                            setCliente((prev: Cliente) => ({ ...prev, religion: value }));
                                                        } else {
                                                            setReligion({ IdReligion: 0, religion: "Seleccione una religion" })
                                                            setCliente((prev: Cliente) => ({ ...prev, religion: { IdReligion: 0, religion: "Seleccione una religion" } }));

                                                        }
                                                    }}
                                                    defaultValue={{ IdReligion: 0, religion: "Seleccione una religion" }}
                                                    value={religion}
                                                // clearIcon={<Clear />} // Ocultar el botón de limpiar                                                    
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    {
                                        cliente.religion.IdReligion === 12 &&
                                        <Col style={styleCol} >
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18 }} >
                                                    Especificacion Otra Religion
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                        style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                        value={cliente.descripcionOtraReligion}
                                                        id="descripcionOtraReligion"
                                                        type="text"
                                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                            setCliente((prev: Cliente) => ({ ...prev, descripcionOtraReligion: value }));
                                                        }}
                                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                            event.target.select();
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Imagen de referencia 1
                                            </Label>

                                            <InputGroup>
                                                <img
                                                    style={{ width: 175, height: 175, cursor: "pointer" }}
                                                    src={cliente.imgFotoPersona01.length === 0 ? imgAgregar : cliente.imgFotoPersona01} alt="No data"
                                                    onClick={() => {
                                                        document.getElementById("cliente.imgFotoPersona01")?.click();
                                                    }}
                                                />
                                                <Input
                                                    hidden
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                    // value={cliente.imgFotoPersona01}
                                                    id="cliente.imgFotoPersona01"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                                                        // setCliente((prev: Cliente) => ({ ...prev, descripcionOtraReligion: value }));
                                                        const file = event.target.files?.[0];
                                                        console.log(file);
                                                        if (file) {

                                                            const base64: string = await Image.convertirImagenABase64(file);
                                                            console.log(base64);
                                                            setCliente((prev: Cliente) => ({ ...prev, imgFotoPersona01: base64 }));
                                                        }
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        // event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                            {cliente.imgFotoPersona01.length > 0 &&
                                                <Label
                                                    style={{ fontSize: 16, color: colors.red, textDecorationLine: "underline", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setCliente((prev: Cliente) => ({ ...prev, imgFotoPersona01: "" }));
                                                    }}
                                                >
                                                    Quitar imagen
                                                </Label>
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Imagen de referencia 2
                                            </Label>

                                            <InputGroup>
                                                <img
                                                    style={{ width: 175, height: 175, cursor: "pointer" }}
                                                    src={cliente.imgFotoPersona02.length === 0 ? imgAgregar : cliente.imgFotoPersona02} alt="No data"
                                                    onClick={() => {
                                                        document.getElementById("cliente.imgFotoPersona02")?.click();
                                                    }}
                                                />
                                                <Input
                                                    hidden
                                                    style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                    // value={cliente.imgFotoPersona01}
                                                    id="cliente.imgFotoPersona02"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                                                        // setCliente((prev: Cliente) => ({ ...prev, descripcionOtraReligion: value }));
                                                        const file = event.target.files?.[0];
                                                        console.log(file);
                                                        if (file) {

                                                            const base64: string = await Image.convertirImagenABase64(file);
                                                            console.log(base64);
                                                            setCliente((prev: Cliente) => ({ ...prev, imgFotoPersona02: base64 }));
                                                        }
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        // event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                            {cliente.imgFotoPersona02.length > 0 &&
                                                <Label
                                                    style={{ fontSize: 16, color: colors.red, textDecorationLine: "underline", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setCliente((prev: Cliente) => ({ ...prev, imgFotoPersona02: "" }));
                                                    }}
                                                >
                                                    Quitar imagen
                                                </Label>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </TabPanel>

                            {/* Empleo / Negocio */}
                            <TabPanel value="2" style={{ padding: 0 }} >
                                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                                    <Col>
                                        <Row>
                                            <Label style={{ fontSize: 24, }} >
                                                {(cliente.trabajoNegocio.esTrabajador === 0 && cliente.trabajoNegocio.tieneNegocio === 0) && "EMPLEO / NEGOCIO"}
                                                {(cliente.trabajoNegocio.esTrabajador === 1 && cliente.trabajoNegocio.tieneNegocio === 0) && "EMPLEO"}
                                                {(cliente.trabajoNegocio.esTrabajador === 0 && cliente.trabajoNegocio.tieneNegocio === 1) && "NEGOCIO"}
                                            </Label>
                                        </Row>
                                        <Row>
                                            <Label
                                                style={{ color: colors.link, cursor: "pointer", textDecorationLine: "underline", marginTop: -12 }}
                                                onClick={() => {
                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, esTrabajador: 0, tieneNegocio: 0, } }));
                                                }}
                                            >
                                                Cambiar categoría
                                            </Label>
                                        </Row>
                                    </Col>
                                    <Col style={{ textAlign: "right" }} >
                                        <IconButton
                                            onClick={async () => {
                                                changeIndexPage(1);
                                            }}
                                            style={{ backgroundColor: colors.blue, color: colors.white01, marginRight: 15 }}
                                            title={"Recargar datos del cliente"}
                                        >
                                            <Search />
                                        </IconButton>
                                        {
                                            cliente.IdCliente.length > 0 &&
                                            <IconButton
                                                onClick={async () => {
                                                    await cargarCliente(cliente.IdCliente);
                                                    toast.info("Datos cargados");
                                                }}
                                                style={{ backgroundColor: colors.blue, color: colors.white01, marginRight: 15 }}
                                                title={"Recargar datos del cliente"}
                                            >
                                                <Cached />
                                            </IconButton>
                                        }


                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        (cliente.trabajoNegocio.esTrabajador === 0 && cliente.trabajoNegocio.tieneNegocio === 0) &&
                                        < Col style={{ minWidth: "100%", width: "100%", maxWidth: "100%" }} >
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18 }} >
                                                    Escoge una opción
                                                </Label>
                                                <InputGroup>
                                                    <Button
                                                        style={{ ...Styles.button, width: data.isDesktop ? 200 : "100%" }}
                                                        onClick={() => setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, esTrabajador: 1, tieneNegocio: 0, } }))}
                                                    >
                                                        Soy Empleado
                                                    </Button>
                                                    <Button
                                                        style={{ ...Styles.button, width: data.isDesktop ? 200 : "100%", marginLeft: data.isDesktop ? 10 : 0, marginTop: data.isDesktop ? 0 : 10 }}
                                                        onClick={() => setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, esTrabajador: 0, tieneNegocio: 1 } }))}
                                                    >
                                                        Tengo Negocio
                                                    </Button>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    }

                                </Row>
                                <DropdownItem divider />
                                {
                                    !(cliente.trabajoNegocio.esTrabajador === 0 && cliente.trabajoNegocio.tieneNegocio === 0) &&
                                    <>
                                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Puesto/Categoría
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                            value={cliente.trabajoNegocio.puestoCategoria}
                                                            id="puestoCategoria"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, puestoCategoria: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Teléfono
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                            value={Number.formatPhoneNumber(cliente.trabajoNegocio.telefono)}
                                                            id="telefono"
                                                            title={`Marcar a ${Number.formatPhoneNumber(cliente.trabajoNegocio.telefono)}`}
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = value.replace("-", "");
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, telefono: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Ingreso Mensual
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                            value={`${cliente.trabajoNegocio.ingresoMensual}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, ingresoMensual: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, ingresoMensual: Moneda.castNumeroConDecimales(value.replace(",", "")) } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Punto de Referencia
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                            value={cliente.trabajoNegocio.puntoReferencia}
                                                            id="puntoReferencia"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, puntoReferencia: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Antigüedad en tu Trabajo
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                            value={cliente.trabajoNegocio.antiguedad}
                                                            id="antiguedad"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, antiguedad: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >


                                            <Col style={{ minWidth: "60%", width: "60%", maxWidth: "60%" }} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Dias que Trabajas
                                                    </Label>
                                                    {/* <Row>
                                                        <Col> */}
                                                    {data.isDesktop ?
                                                        <InputGroup>
                                                            <Input
                                                                style={{ boxShadow: "none", borderRadius: 5 }}
                                                                type="checkbox"
                                                                checked={cliente.trabajoNegocio.diasLaborales.lunes === 1}
                                                                onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                    const value: number = checked ? 1 : 0;
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, lunes: value } } }));
                                                                }}
                                                            />
                                                            <Label style={{ marginLeft: 10 }} > Lunes </Label>
                                                            <Input
                                                                style={{ boxShadow: "none", marginLeft: 14, borderRadius: 5 }}
                                                                type="checkbox"
                                                                checked={cliente.trabajoNegocio.diasLaborales.martes === 1}
                                                                onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                    const value: number = checked ? 1 : 0;
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, martes: value } } }));
                                                                }}
                                                            />
                                                            <Label style={{ marginLeft: 10 }} > Martes </Label>
                                                            <Input
                                                                style={{ boxShadow: "none", marginLeft: 12, borderRadius: 5 }}
                                                                type="checkbox"
                                                                checked={cliente.trabajoNegocio.diasLaborales.miercoles === 1}
                                                                onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                    const value: number = checked ? 1 : 0;
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, miercoles: value } } }));
                                                                }}
                                                            />
                                                            <Label style={{ marginLeft: 10 }} > Miércoles </Label>
                                                            {/* </InputGroup> */}
                                                            {/* </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col> */}
                                                            {/* <InputGroup> */}
                                                            <Input
                                                                style={{ boxShadow: "none", marginLeft: 7, borderRadius: 5 }}
                                                                type="checkbox"
                                                                checked={cliente.trabajoNegocio.diasLaborales.jueves === 1}
                                                                onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                    const value: number = checked ? 1 : 0;
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, jueves: value } } }));
                                                                }}
                                                            />
                                                            <Label style={{ marginLeft: 10 }} > Jueves </Label>
                                                            <Input
                                                                style={{ boxShadow: "none", marginLeft: 7, borderRadius: 5 }}
                                                                type="checkbox"
                                                                checked={cliente.trabajoNegocio.diasLaborales.viernes === 1}
                                                                onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                    const value: number = checked ? 1 : 0;
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, viernes: value } } }));
                                                                }}
                                                            />
                                                            <Label style={{ marginLeft: 10 }} > Viernes </Label>
                                                            <Input
                                                                style={{ boxShadow: "none", marginLeft: 7, borderRadius: 5 }}
                                                                type="checkbox"
                                                                checked={cliente.trabajoNegocio.diasLaborales.sabado === 1}
                                                                onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                    const value: number = checked ? 1 : 0;
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, sabado: value } } }));
                                                                }}
                                                            />
                                                            <Label style={{ marginLeft: 10 }} > Sábado </Label>
                                                            {/* </InputGroup> */}
                                                            {/* </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col> */}
                                                            {/* <InputGroup> */}
                                                            <Input
                                                                style={{ boxShadow: "none", marginLeft: 7, borderRadius: 5 }}
                                                                type="checkbox"
                                                                checked={cliente.trabajoNegocio.diasLaborales.domingo === 1}
                                                                onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                    const value: number = checked ? 1 : 0;
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, domingo: value } } }));
                                                                }}
                                                            />
                                                            <Label style={{ marginLeft: 10 }} > Domingo </Label>
                                                        </InputGroup>
                                                        :
                                                        <>
                                                            <InputGroup>
                                                                <Input
                                                                    style={{ boxShadow: "none", borderRadius: 5 }}
                                                                    type="checkbox"
                                                                    checked={cliente.trabajoNegocio.diasLaborales.lunes === 1}
                                                                    onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                        const value: number = checked ? 1 : 0;
                                                                        setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, lunes: value } } }));
                                                                    }}
                                                                />
                                                                <Label style={{ marginLeft: 10 }} > Lunes </Label>
                                                            </InputGroup>
                                                            <InputGroup>
                                                                <Input
                                                                    style={{ boxShadow: "none", marginLeft: 0, borderRadius: 5 }}
                                                                    type="checkbox"
                                                                    checked={cliente.trabajoNegocio.diasLaborales.martes === 1}
                                                                    onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                        const value: number = checked ? 1 : 0;
                                                                        setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, martes: value } } }));
                                                                    }}
                                                                />
                                                                <Label style={{ marginLeft: 10 }} > Martes </Label>
                                                            </InputGroup>
                                                            <InputGroup>
                                                                <Input
                                                                    style={{ boxShadow: "none", marginLeft: 0, borderRadius: 5 }}
                                                                    type="checkbox"
                                                                    checked={cliente.trabajoNegocio.diasLaborales.miercoles === 1}
                                                                    onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                        const value: number = checked ? 1 : 0;
                                                                        setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, miercoles: value } } }));
                                                                    }}
                                                                />
                                                                <Label style={{ marginLeft: 10 }} > Miércoles </Label>
                                                            </InputGroup>
                                                            <InputGroup>
                                                                <Input
                                                                    style={{ boxShadow: "none", marginLeft: 0, borderRadius: 5 }}
                                                                    type="checkbox"
                                                                    checked={cliente.trabajoNegocio.diasLaborales.jueves === 1}
                                                                    onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                        const value: number = checked ? 1 : 0;
                                                                        setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, jueves: value } } }));
                                                                    }}
                                                                />
                                                                <Label style={{ marginLeft: 10 }} > Jueves </Label>
                                                            </InputGroup>
                                                            <InputGroup>
                                                                <Input
                                                                    style={{ boxShadow: "none", marginLeft: 0, borderRadius: 5 }}
                                                                    type="checkbox"
                                                                    checked={cliente.trabajoNegocio.diasLaborales.viernes === 1}
                                                                    onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                        const value: number = checked ? 1 : 0;
                                                                        setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, viernes: value } } }));
                                                                    }}
                                                                />
                                                                <Label style={{ marginLeft: 10 }} > Viernes </Label>
                                                            </InputGroup>
                                                            <InputGroup>
                                                                <Input
                                                                    style={{ boxShadow: "none", marginLeft: 0, borderRadius: 5 }}
                                                                    type="checkbox"
                                                                    checked={cliente.trabajoNegocio.diasLaborales.sabado === 1}
                                                                    onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                        const value: number = checked ? 1 : 0;
                                                                        setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, sabado: value } } }));
                                                                    }}
                                                                />
                                                                <Label style={{ marginLeft: 10 }} > Sábado </Label>
                                                            </InputGroup>
                                                            <InputGroup>
                                                                <Input
                                                                    style={{ boxShadow: "none", marginLeft: 0, borderRadius: 5 }}
                                                                    type="checkbox"
                                                                    checked={cliente.trabajoNegocio.diasLaborales.domingo === 1}
                                                                    onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                                                                        const value: number = checked ? 1 : 0;
                                                                        setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, diasLaborales: { ...prev.trabajoNegocio.diasLaborales, domingo: value } } }));
                                                                    }}
                                                                />
                                                                <Label style={{ marginLeft: 10 }} > Domingo </Label>
                                                            </InputGroup>
                                                        </>

                                                    }
                                                    {/* </Col>
                                                    </Row> */}
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Hora Entrada Trabajo
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                            value={cliente.trabajoNegocio.horario.entrada}
                                                            id="entrada"
                                                            type="time"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, horario: { ...prev.trabajoNegocio.horario, entrada: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Hora salida Trabajo
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                            value={cliente.trabajoNegocio.horario.salida}
                                                            id="salida"
                                                            type="time"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, horario: { ...prev.trabajoNegocio.horario, salida: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row style={styleRow} >
                                            <Col style={{ minWidth: "100%", width: "100%", maxWidth: "100%" }} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Dirección
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                            value={cliente.trabajoNegocio.direccion}
                                                            id="direccion"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, direccion: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>
                                }

                                {
                                    cliente.trabajoNegocio.tieneNegocio === 1 &&
                                    <>
                                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                                            <Col>
                                                <Label style={{ fontSize: 24, }} >
                                                    Análisis de negocio
                                                </Label>
                                            </Col>
                                        </Row>
                                        <DropdownItem divider />
                                        <Row style={styleRow} >
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Ventas Semanales
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 18 }}
                                                            value={`${cliente.trabajoNegocio.ventasSemanales}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = value.replace("$ ", "");
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, ventasSemanales: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                let value: string = event.target.value.replace(".00", "").replace("$ ", "");
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, ventasSemanales: value } }));
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                value = value.replace("$ ", "");
                                                                if (value.split(".").length === 2) { if (value.split(".")[1].length === 1) value = value + "0"; } else { value = value + ".00"; }
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, ventasSemanales: value } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Inversion Semanal
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 18 }}
                                                            value={`${cliente.trabajoNegocio.inversionSemanal}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = value.replace("$ ", "");
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, inversionSemanal: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                let value: string = event.target.value.replace(".00", "").replace("$ ", "");
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, inversionSemanal: value } }));
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                value = value.replace("$ ", "");
                                                                if (value.split(".").length === 2) { if (value.split(".")[1].length === 1) value = value + "0"; } else { value = value + ".00"; }
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, inversionSemanal: value } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Tipo de Local
                                                    </Label>
                                                    <InputGroup>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={tiposLocales}
                                                            sx={Styles.select}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            getOptionLabel={(option: TipoLocal) => option.tipoLocal}
                                                            onChange={(event: ChangeEvent<{}>, value: TipoLocal | null) => {
                                                                if (value !== null) {
                                                                    setTipoLocal(cliente.trabajoNegocio.TipoLocal);
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, TipoLocal: value } }));
                                                                } else {
                                                                    setTipoLocal({ IdTipoLocal: 0, tipoLocal: "Seleccione un tipo de local" });
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, TipoLocal: { IdTipoLocal: 0, tipoLocal: "Seleccione un tipo de local" } } }));
                                                                }
                                                            }}
                                                            defaultValue={{ IdTipoLocal: 0, tipoLocal: "Seleccione un tipo de local" }}
                                                            value={tipoLocal}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Gastos de Renta
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 18 }}
                                                            value={`${cliente.trabajoNegocio.gastos.renta}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, renta: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, renta: Moneda.castNumeroConDecimales(value.replace(",", "")) } } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Gastos de Agua
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 18 }}
                                                            value={`${cliente.trabajoNegocio.gastos.agua}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, agua: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, agua: Moneda.castNumeroConDecimales(value.replace(",", "")) } } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Gastos de Luz
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 18 }}
                                                            value={`${cliente.trabajoNegocio.gastos.luz}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, luz: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, luz: Moneda.castNumeroConDecimales(value.replace(",", "")) } } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Gastos de Internet
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 18 }}
                                                            value={`${cliente.trabajoNegocio.gastos.internet}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = value.replace("$ ", "");
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, internet: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                let value: string = event.target.value.replace(".00", "").replace("$ ", "");
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, internet: value } } }));
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                value = value.replace("$ ", "");
                                                                if (value.split(".").length === 2) { if (value.split(".")[1].length === 1) value = value + "0"; } else { value = value + ".00"; }
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, internet: value } } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Otros Gastos
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                            value={`${cliente.trabajoNegocio.gastos.otros}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, otros: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, gastos: { ...prev.trabajoNegocio.gastos, otros: Moneda.castNumeroConDecimales(value.replace(",", "")) } } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Cuento Tiene de Mercancía
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                            value={`${cliente.trabajoNegocio.cuantoTieneMercancia}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, cuantoTieneMercancia: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, cuantoTieneMercancia: Moneda.castNumeroConDecimales(value.replace(",", "")) } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        ¿Cuanto Dinero le Deben?
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                            value={`${cliente.trabajoNegocio.cuentoDineroLeDeben}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = value.replace("$ ", "");
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, cuentoDineroLeDeben: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                let value: string = event.target.value.replace(".00", "").replace("$ ", "");
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, cuentoDineroLeDeben: value } }));
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                value = value.replace("$ ", "");
                                                                if (value.split(".").length === 2) { if (value.split(".")[1].length === 1) value = value + "0"; } else { value = value + ".00"; }
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, cuentoDineroLeDeben: value } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        ¿Cuánto Tiene Efectivo/Ahorro?
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                            value={`${cliente.trabajoNegocio.dineroEfectivoAhorrado}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = value.replace("$ ", "");
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, dineroEfectivoAhorrado: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                let value: string = event.target.value.replace(".00", "").replace("$ ", "");
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, dineroEfectivoAhorrado: value } }));
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                value = value.replace("$ ", "");
                                                                if (value.split(".").length === 2) { if (value.split(".")[1].length === 1) value = value + "0"; } else { value = value + ".00"; }
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, dineroEfectivoAhorrado: value } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={{ minWidth: "40%", width: "40%", maxWidth: "40%" }} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        ¿Cuánto Calcula que Tiene de Ganancia en su Negocio?
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                            value={cliente.trabajoNegocio.cuantoCalculaTieneGananciaEnSuNegocio}
                                                            id="cuantoCalculaTieneGananciaEnSuNegocio"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, cuantoCalculaTieneGananciaEnSuNegocio: value } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        ¿Tienen Otros Ingresos?
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ boxShadow: "none", marginLeft: 0, borderRadius: 5 }}
                                                            type="checkbox"
                                                            checked={cliente.trabajoNegocio.tieneOtroingreso === 1}
                                                            onChange={() => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, tieneOtroingreso: 1 } }));
                                                                Tiempo.sleep(0.3).then(() => Components.focus("trabajoNegocio-otroIngresoDescripcion"));
                                                            }}
                                                        />
                                                        <Label
                                                            style={{ marginLeft: 7 }}
                                                            onClick={() => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, tieneOtroingreso: 1 } }));
                                                                Tiempo.sleep(0.3).then(() => Components.focus("trabajoNegocio-otroIngresoDescripcion"));
                                                            }}
                                                        >
                                                            Si
                                                        </Label>
                                                        <Input
                                                            style={{ boxShadow: "none", marginLeft: 7, borderRadius: 5 }}
                                                            type="checkbox"
                                                            checked={cliente.trabajoNegocio.tieneOtroingreso === 0}
                                                            onChange={() => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, tieneOtroingreso: 0 } }));
                                                            }}
                                                        />
                                                        <Label
                                                            style={{ marginLeft: 7 }}
                                                            onClick={() => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, tieneOtroingreso: 0 } }));
                                                            }}
                                                        >
                                                            No
                                                        </Label>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            {
                                                cliente.trabajoNegocio.tieneOtroingreso === 1 &&
                                                <Col style={styleCol} >
                                                    <FormGroup>
                                                        <Label style={{ color: colors.black, fontSize: 18 }} >
                                                            Describa Sus Otros Ingresos
                                                        </Label>
                                                        <InputGroup>
                                                            <Input
                                                                style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                                value={cliente.trabajoNegocio.otroIngresoDescripcion}
                                                                id="trabajoNegocio-otroIngresoDescripcion"
                                                                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, otroIngresoDescripcion: value } }));
                                                                }}
                                                                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                    event.target.select();
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            }
                                        </Row>

                                        <DropdownItem divider />
                                        <Row style={styleRow} >
                                            <Col>
                                                <Label style={{ fontSize: 24, }} >
                                                    DECLARACIÓN PATRIMONIAL
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row style={styleRow} >
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Vivienda
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                            value={`${cliente.trabajoNegocio.declaracionPatrimonial.vivienda}`}
                                                            id="ingresoMensual"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, declaracionPatrimonial: { ...prev.trabajoNegocio.declaracionPatrimonial, vivienda: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, declaracionPatrimonial: { ...prev.trabajoNegocio.declaracionPatrimonial, vivienda: Moneda.castNumeroConDecimales(value) } } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Vehículos
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                            value={`${cliente.trabajoNegocio.declaracionPatrimonial.vehiculos}`}
                                                            id="vehiculos"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, declaracionPatrimonial: { ...prev.trabajoNegocio.declaracionPatrimonial, vehiculos: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, declaracionPatrimonial: { ...prev.trabajoNegocio.declaracionPatrimonial, vehiculos: Moneda.castNumeroConDecimales(value.replace(",", "")) } } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Equipos
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                            value={`${cliente.trabajoNegocio.declaracionPatrimonial.equipo}`}
                                                            id="equipo"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, declaracionPatrimonial: { ...prev.trabajoNegocio.declaracionPatrimonial, equipo: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, declaracionPatrimonial: { ...prev.trabajoNegocio.declaracionPatrimonial, equipo: Moneda.castNumeroConDecimales(value.replace(",", "")) } } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Otros
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                                        >
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{ ...Styles.input, borderBottomColor: colors.black, marginTop: 0 }}
                                                            value={`${cliente.trabajoNegocio.declaracionPatrimonial.otros}`}
                                                            id="otros"
                                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                value = Number.singleNumbers(value)
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, declaracionPatrimonial: { ...prev.trabajoNegocio.declaracionPatrimonial, otros: value } } }));
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                            onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, declaracionPatrimonial: { ...prev.trabajoNegocio.declaracionPatrimonial, otros: Moneda.castNumeroConDecimales(value.replace(",", "")) } } }));
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col style={styleCol} >
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18 }} >
                                                        Ha Tenido Otros Créditos
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={{ boxShadow: "none", marginLeft: 0, borderRadius: 5 }}
                                                            type="checkbox"
                                                            checked={cliente.trabajoNegocio.haTenidoCreditos === 1}
                                                            onChange={() => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, haTenidoCreditos: 1 } }));
                                                                Tiempo.sleep(0.3).then(() => Components.focus("trabajoNegocio-institucionDondeLeDieronElCredito"));
                                                            }}
                                                        />
                                                        <Label
                                                            style={{ marginLeft: 7 }}
                                                            onClick={() => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, haTenidoCreditos: 1 } }));
                                                                Tiempo.sleep(0.3).then(() => Components.focus("trabajoNegocio-institucionDondeLeDieronElCredito"));
                                                            }}
                                                        >
                                                            Si
                                                        </Label>
                                                        <Input
                                                            style={{ boxShadow: "none", marginLeft: 7, borderRadius: 5 }}
                                                            type="checkbox"
                                                            checked={cliente.trabajoNegocio.haTenidoCreditos === 0}
                                                            onChange={() => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, haTenidoCreditos: 0 } }));
                                                            }}
                                                        />
                                                        <Label
                                                            style={{ marginLeft: 7 }}
                                                            onClick={() => {
                                                                setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, haTenidoCreditos: 0 } }));
                                                            }}
                                                        >
                                                            No
                                                        </Label>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={styleRow} >
                                            {
                                                cliente.trabajoNegocio.haTenidoCreditos === 1 &&
                                                <Col style={styleCol} >
                                                    <FormGroup>
                                                        <Label style={{ color: colors.black, fontSize: 18 }} >
                                                            Institución Donde le Dieron el Crédito
                                                        </Label>
                                                        <InputGroup>
                                                            <Input
                                                                style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                                value={cliente.trabajoNegocio.institucionDondeLeDieronElCredito}
                                                                id="trabajoNegocio-institucionDondeLeDieronElCredito"
                                                                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                                    setCliente((prev: Cliente) => ({ ...prev, trabajoNegocio: { ...prev.trabajoNegocio, institucionDondeLeDieronElCredito: value } }));
                                                                }}
                                                                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                    event.target.select();
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            }
                                        </Row>
                                    </>
                                }

                            </TabPanel>

                            {/* Referencias del cliente (personas) */}
                            <TabPanel value="3" style={{ padding: 0 }} >
                                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                                    <Col>
                                        <Row>
                                            <Label style={{ fontSize: 24, }} >
                                                REFERENCIAS ( PERSONAS )
                                            </Label>
                                        </Row>
                                    </Col>
                                    <Col style={{ textAlign: "right" }} >
                                        {
                                            cliente.IdCliente.length > 0 &&

                                            <IconButton
                                                onClick={async () => {
                                                    await cargarCliente(cliente.IdCliente);
                                                    toast.info("Datos cargados");
                                                }}
                                                style={{ backgroundColor: colors.blue, color: colors.white01, marginRight: 15 }}
                                                title={"Recargar datos del cliente"}
                                            >
                                                <PostAdd />
                                            </IconButton>
                                        }
                                        <IconButton
                                            onClick={async () => {
                                                changeIndexPage(1);
                                            }}
                                            style={{ backgroundColor: colors.blue, color: colors.white01, marginRight: 15 }}
                                            title={"Recargar datos del cliente"}
                                        >
                                            <Search />
                                        </IconButton>
                                        {
                                            cliente.IdCliente.length > 0 &&

                                            <IconButton
                                                onClick={async () => {
                                                    await cargarCliente(cliente.IdCliente);
                                                    toast.info("Datos cargados");
                                                }}
                                                style={{ backgroundColor: colors.blue, color: colors.white01, marginRight: 15 }}
                                                title={"Recargar datos del cliente"}
                                            >
                                                <Cached />
                                            </IconButton>
                                        }


                                    </Col>
                                </Row>
                                <DropdownItem divider />
                                {data.isMovil && <Row><Col><Label style={{ color: colors.black, fontSize: 20, fontWeight: "bold" }} >Persona Uno</Label></Col></Row>}
                                <Row>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Nombre Completo y Apellido
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.refenciasPersonas.personaUno.nombreCompleto}
                                                    id="refenciasPersonas-personaUno-nombreCompleto"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, refenciasPersonas: { ...prev.refenciasPersonas, personaUno: { ...prev.refenciasPersonas.personaUno, nombreCompleto: value } } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Parentesco
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.refenciasPersonas.personaUno.parentesco}
                                                    id="refenciasPersonas-personaUno-parentesco"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, refenciasPersonas: { ...prev.refenciasPersonas, personaUno: { ...prev.refenciasPersonas.personaUno, parentesco: value } } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={data.isDesktop ? { minWidth: "40%", width: "40%", maxWidth: "40%" } : { minWidth: "100%", width: "100%", maxWidth: "100%" }} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Dirección
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.refenciasPersonas.personaUno.direccion}
                                                    id="refenciasPersonas-personaUno-direccion"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, refenciasPersonas: { ...prev.refenciasPersonas, personaUno: { ...prev.refenciasPersonas.personaUno, direccion: value } } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Numero Teléfono {cliente.refenciasPersonas.personaUno.telefono}
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.blue, cursor: "pointer" }}
                                                    title={`Marcar a ${Number.formatPhoneNumber(cliente.refenciasPersonas.personaUno.telefono)}`}
                                                >
                                                    <Phone />
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={Number.formatPhoneNumber(cliente.refenciasPersonas.personaUno.telefono)}
                                                    id="telefono"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, refenciasPersonas: { ...prev.refenciasPersonas, personaUno: { ...prev.refenciasPersonas.personaUno, telefono: value } } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {data.isMovil && <Row><Col><Label style={{ color: colors.black, fontSize: 20, fontWeight: "bold" }} >Persona Dos</Label></Col></Row>}
                                <Row>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Nombre Completo y Apellido
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.refenciasPersonas.personaDos.nombreCompleto}
                                                    id="refenciasPersonas-personaUno-nombreCompleto"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, refenciasPersonas: { ...prev.refenciasPersonas, personaDos: { ...prev.refenciasPersonas.personaDos, nombreCompleto: value } } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Parentesco
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.refenciasPersonas.personaDos.parentesco}
                                                    id="refenciasPersonas-personaUno-parentesco"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, refenciasPersonas: { ...prev.refenciasPersonas, personaDos: { ...prev.refenciasPersonas.personaDos, parentesco: value } } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={data.isDesktop ? { minWidth: "40%", width: "40%", maxWidth: "40%" } : { minWidth: "100%", width: "100%", maxWidth: "100%" }} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Dirección
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={cliente.refenciasPersonas.personaDos.direccion}
                                                    id="refenciasPersonas-personaUno-direccion"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, refenciasPersonas: { ...prev.refenciasPersonas, personaDos: { ...prev.refenciasPersonas.personaDos, direccion: value } } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col style={styleCol} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Numero Teléfono {cliente.refenciasPersonas.personaDos.telefono}
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{ ...Styles.input, backgroundColor: "transparent", color: colors.blue, cursor: "pointer" }}
                                                    title={`Marcar a ${Number.formatPhoneNumber(cliente.refenciasPersonas.personaDos.telefono)}`}
                                                >
                                                    <Phone />
                                                </InputGroupText>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={Number.formatPhoneNumber(cliente.refenciasPersonas.personaDos.telefono)}
                                                    id="telefono"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setCliente((prev: Cliente) => ({ ...prev, refenciasPersonas: { ...prev.refenciasPersonas, personaDos: { ...prev.refenciasPersonas.personaDos, telefono: value } } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </TabPanel>

                            <TabPanel value="4" style={{ padding: 0 }} >
                                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                                    <Col>
                                        <Row>
                                            <Label style={{ fontSize: 24, }} >
                                                AVAL DE {cliente.IdCliente.length === 0 ? `"CLIENTE"` : `${cliente.nombres} ${cliente.paterno} ${cliente.materno}`}
                                            </Label>
                                        </Row>
                                    </Col>
                                </Row>
                                <DropdownItem divider />
                                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                                    <Col style={{ textAlign: "right" }} >
                                        <IconButton
                                            style={{ backgroundColor: colors.blue, color: colors.white01 }}
                                            onClick={() => {
                                                setIsOpenModalListaAval(true);
                                            }}
                                        >
                                            <Search />
                                        </IconButton>
                                    </Col>
                                </Row>
                                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 0 }} >
                                    <Col style={{ minWidth: data.isDesktop ? "40%" : "100%", width: data.isDesktop ? "40%" : "100%", maxWidth: data.isDesktop ? "40%" : "100%" }} >
                                        <FormGroup>
                                            <Label style={{ color: colors.black, fontSize: 18 }} >
                                                Nombre Completo y Apellido
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    style={{ ...Styles.input, borderBottomColor: colors.black }}
                                                    value={`${aval.nombres} ${aval.paterno} ${aval.materno}`}
                                                    // id="refenciasPersonas-personaUno-nombreCompleto"
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        // setCliente((prev: Cliente) => ({ ...prev, refenciasPersonas: { ...prev.refenciasPersonas, personaUno: { ...prev.refenciasPersonas.personaUno, nombreCompleto: value } } }));
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        // event.target.select();
                                                    }}
                                                    disable
                                                />
                                                <InputGroupText
                                                    style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                                    onClick={() => {
                                                        setTextBuscar("");
                                                        Tiempo.sleep(1).then(() => { setClienteLista([]) });
                                                        Components.focus("textBuscar");
                                                        navigate(`/dashboard/?page=1`);
                                                    }}
                                                >
                                                    
                                                </InputGroupText>
                                            </InputGroup>
                                            <Label
                                                style={{ color: colors.blue, fontSize: 16, textDecorationLine: "underline", cursor: "pointer" }}
                                                onClick={() => {
                                                    cargarCliente(cliente.IdAval);
                                                    setValue("1");
                                                }}
                                            >
                                                Ver todos los datos del aval
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </TabPanel>

                            {/* Imágenes del Cliente (Perdil, INE, Fachada Casa) */}
                            {/* <TabPanel value="4" style={{ padding: 0 }} >
                                <Row style={styleRow} >
                                    <Col style={{ textAlign: "right" }} >
                                        <IconButton
                                            onClick={async () => {
                                                changeIndexPage(1);
                                            }}
                                            style={{ backgroundColor: colors.blue, color: colors.white01, marginRight: 15 }}
                                            title={"Recargar datos del cliente"}
                                        >
                                            <Search />
                                        </IconButton>
                                        {
                                            cliente.IdCliente.length > 0 &&
                                            <IconButton
                                                onClick={async () => {
                                                    await cargarCliente(cliente.IdCliente);
                                                    toast.info("Datos cargados");
                                                }}
                                                style={{ backgroundColor: colors.blue, color: colors.white01, marginRight: 15 }}
                                                title={"Recargar datos del cliente"}
                                            >
                                                <Cached />
                                            </IconButton>
                                        }


                                    </Col>
                                </Row>
                                <Row style={styleRow} >
                                    <Col sm="12" md="6" lg="3" className="mb-4"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white02, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18 }} >
                                                    Foto de la persona
                                                </Label>
                                                <DropdownItem divider />
                                                <Block height={"2vh"} />
                                                <img
                                                    src={cliente.imgFotoPersona.length === 0 ? imgProfile : cliente.imgFotoPersona}
                                                    alt="/*-"
                                                    style={{ width: "60%", marginRight: "20%", marginLeft: "20%", marginTop: 0, cursor: "pointer" }}
                                                    onClick={() => document.getElementById('imgFotoPersona')?.click()}
                                                />
                                                <Block height={"2vh"} />
                                                <Input
                                                    id="imgFotoPersona"
                                                    type="file"
                                                    accept=""
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0];
                                                            try {
                                                                const value: string = await Image.convertirImagenABase64(file);
                                                                setCliente((prev: Cliente) => ({ ...prev, imgFotoPersona: value }));
                                                            } catch (error) {
                                                                console.error(error);
                                                            }
                                                        }
                                                    }}

                                                />
                                            </FormGroup>
                                        </Card>
                                    </Col>
                                    <Col sm="12" md="6" lg="3" className="mb-4"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white02, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18 }} >
                                                    Fachada de la Casa
                                                </Label>
                                                <DropdownItem divider />
                                                <Block height={"2vh"} />
                                                <img
                                                    src={cliente.imgFachadaCasa.length === 0 ? imgProfile : cliente.imgFachadaCasa}
                                                    alt="/*-"
                                                    style={{ width: "60%", marginRight: "20%", marginLeft: "20%", marginTop: 0, cursor: "pointer" }}
                                                    onClick={() => document.getElementById('imgFachadaCasa')?.click()}
                                                />
                                                <Block height={"2vh"} />
                                                <Input
                                                    id="imgFachadaCasa"
                                                    type="file"
                                                    accept=""
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0];
                                                            try {
                                                                const value: string = await Image.convertirImagenABase64(file);
                                                                setCliente((prev: Cliente) => ({ ...prev, imgFachadaCasa: value }));
                                                            } catch (error) {
                                                                console.error(error);
                                                            }
                                                        }
                                                    }}

                                                />
                                            </FormGroup>
                                        </Card>
                                    </Col>
                                    <Col sm="12" md="6" lg="3" className="mb-4"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white02, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18 }} >
                                                    INE Frontal
                                                </Label>
                                                <DropdownItem divider />
                                                <Block height={"2vh"} />
                                                <img
                                                    src={cliente.imgINE.length === 0 ? imgProfile : cliente.imgINE}
                                                    alt="/*-"
                                                    style={{ width: "60%", marginRight: "20%", marginLeft: "20%", marginTop: 0, cursor: "pointer" }}
                                                    onClick={() => document.getElementById('imgINE')?.click()}
                                                />
                                                <Block height={"2vh"} />
                                                <Input
                                                    id="imgINE"
                                                    type="file"
                                                    accept="*"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0];
                                                            try {
                                                                const value: string = await Image.convertirImagenABase64(file);
                                                                setCliente((prev: Cliente) => ({ ...prev, imgINE: value }));
                                                            } catch (error) {
                                                                console.error(error);
                                                            }
                                                        }
                                                    }}

                                                />
                                            </FormGroup>
                                        </Card>
                                    </Col>
                                    <Col sm="12" md="6" lg="3" className="mb-4"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white02, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18 }} >
                                                    INE Reverso
                                                </Label>
                                                <DropdownItem divider />
                                                <Input
                                                    id=""
                                                    type="file"
                                                    accept="*"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0];
                                                            try {

                                                            } catch (error) {
                                                                console.error(error);
                                                            }
                                                        }
                                                    }}

                                                />
                                            </FormGroup>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPanel> */}

                        </TabContext>
                    </Box>

                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 30 }} >
                        <Col style={{ textAlign: "right" }} >
                            <Button
                                id="btn-cliente-nuevo"
                                style={{ ...Styles.button, backgroundColor: colors.white01, color: colors.blue, borderColor: colors.blue, width: 150, marginRight: 20 }}
                                onClick={() => {
                                    setCliente(ClienteModel.getEstructura());
                                    setEstadoNacimiento({ IdEstado: 0, estado: "Seleccione un estado" });
                                    setCiudadNacimiento({ IdCiudad: 0, ciudad: "Seleccione una ciudad" });
                                    setNivelEstudio({ IdNivelEstudio: 0, nivelEstudio: "Seleccione un nivel de estudio" });
                                    setEstadoCivil({ IdEstadoCivil: 0, estadoCivil: "Seleccione un estado civil" });
                                    setEstadoVivienda({ IdEstado: 0, estado: "Seleccione un estado" });
                                    setCiudadVivienda({ IdCiudad: 0, ciudad: "Seleccione una ciudad" });
                                    setLocalidadVivienda({ IdLocalidad: 0, localidad: "Seleccione una localidad" });
                                    setLocalidadesVivienda([]);
                                    Components.focus("nombres");
                                    navigate(`/dashboard/?page=2`);
                                }}
                            >
                                Nuevo Cliente
                            </Button>
                            <Button
                                onClick={() => guardarCliente()}
                                style={{ ...Styles.button, width: 150 }}
                            >
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                    <Block height={"2vh"} />
                </section>
            </main >
            <Modal
                isOpen={isOpenModalErrores}
                size={"lg"}
                style={{ fontFamily: "Montserrat" }}
            >
                <ModalHeader
                    style={{ backgroundColor: colors.blue }}
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.white01, color: colors.blue }}
                            onClick={() => {
                                setIsOpenModalErrores(false);
                                setErroresMensaje([]);
                            }}
                        >
                            <Close />
                        </IconButton>
                    }
                >
                    <Label style={{ color: colors.white01, fontSize: 24 }} >
                        ¡Atención!
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: data.isDesktop ? "70vh" : "", height: data.isDesktop ? "70vh" : "", maxHeight: data.isDesktop ? "70vh" : "", overflowY: "auto" }} >
                    <Row>
                        <Col>
                            <Label style={{ color: colors.black, fontSize: 24 }} >
                                Se encontraron los siguientes errores
                            </Label>
                        </Col>
                    </Row>
                    <DropdownItem divider />
                    {
                        erroresMensaje.map((mensaje: string, index: number) => {
                            return (
                                <Row>
                                    <Col>
                                        <Label style={{ color: colors.red, fontSize: 20 }} >
                                            {index + 1}.- {mensaje}
                                        </Label>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ ...Styles.button, width: "auto" }}
                        onClick={() => {
                            setIsOpenModalErrores(false);
                            setErroresMensaje([]);
                        }}
                    >
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalListaAval}
                size={"lg"}
                style={{ fontFamily: "Montserrat", minWidth: "70%" }}
            >
                <ModalHeader
                    style={{ backgroundColor: colors.blue }}
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.white01, color: colors.blue }}
                            onClick={() => {
                                setIsOpenModalListaAval(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    }
                >
                    <Label style={{ color: colors.white01, fontSize: 24 }} >
                        Lista de personas
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: data.isDesktop ? "70vh" : "", height: data.isDesktop ? "70vh" : "", maxHeight: data.isDesktop ? "70vh" : "", overflowY: "auto" }} >
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Buscar Cliente
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="textBuscar"
                                        type="text"
                                        placeholder="Nombres, Apellido Paterno, Apellido Materno"
                                        value={textBuscar}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setTextBuscar(value)
                                        }}
                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                            if (key === "Enter") listarClientes(textBuscar);
                                        }}
                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.select();
                                        }}
                                        style={Styles.input}
                                    />
                                    <InputGroupText
                                        style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                        onClick={() => {
                                            setTextBuscar("");
                                            Tiempo.sleep(1).then(() => { setClienteLista([]) });
                                            Components.focus("textBuscar");
                                            // navigate(`/dashboard/?page=1`);
                                        }}
                                    >
                                        <Close />
                                    </InputGroupText>
                                    <InputGroupText
                                        style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                        onClick={() => {
                                            // listarClientes(textBuscar);
                                            // Components.focus("textBuscar");
                                            if (textBuscar.length > 0) {
                                                listarClientes(textBuscar);
                                            } else {
                                                toast.warning("No has especificado la búsqueda");
                                                Components.focus("textBuscar");
                                            }
                                        }}
                                    >
                                        <Search />
                                    </InputGroupText>
                                </InputGroup>
                                <Label
                                    style={{ marginTop: 5, color: colors.blue, cursor: "pointer", textDecorationLine: "underline" }}
                                    onClick={() => listarClientes("")}
                                >
                                    Listar a todos los cientes
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 0 }} >
                        <Col>
                            {
                                clienteLista.length === 0 ?
                                    <center>
                                        <Block height={data.isDesktop ? 10 : 0} />
                                        <img src={imgBuscarCliente} alt="/*-" style={{ height: "50vh" }} />
                                    </center>
                                    :
                                    <>
                                        <Block height={data.isDesktop ? 10 : 0} />
                                        <Table
                                            // title={<Label>{cantidadClientes}</Label>}
                                            columns={columnsClientes}
                                            data={clienteLista}
                                        //fixedHeader
                                        />
                                    </>
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
        </>
    );
}

export default InfoCliente;