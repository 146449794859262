import colors from "../../config/Colors.config";

const input = {
    borderWidth: 1,
    boxShadow: "none",
    borderLeftColor: "transparent",
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: colors.blue,
    borderRadius: 0

}

const select = {
    width: "100%",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            boxShadow: "none",
            borderLeftColor: "transparent",
            borderTopColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: colors.black,
        },
        "&:hover fieldset": {
            boxShadow: "none",
            borderLeftColor: "transparent",
            borderTopColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: colors.black,
        },
        "&.Mui-focused fieldset": {
            boxShadow: "none",
            borderLeftColor: "transparent",
            borderTopColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: colors.black,
        },
    },
    "& .MuiAutocomplete-option": {
        fontSize: '16px', // Tamaño de la fuente de las opciones
        "&[aria-selected='true']": {
            backgroundColor: 'lightblue', // Color de fondo de la opción seleccionada
        },
        "&:hover": {
            backgroundColor: 'lightgray', // Color de fondo al pasar el mouse
        },
    },
}

const button = {
    backgroundColor: colors.blue,
    borderColor: colors.blue,
    fontWeight: "bold",
    boxShadow: "none",
    width: "100%",
    borderRadius: 8
}


const Styles = {
    input,
    select,
    button,
}

export default Styles;